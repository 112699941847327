import React from "react";
import "./BenefitsHeadingpara.scss"

const BenefitsHeadingPara = (props) => {
    const login = () => {
        if(window.location.href.includes("teachers") || window.location.href.includes("students")) {
            window.open(process.env.GATSBY_MGMT_LOGIN , "_self")
        } else {
            window.open(process.env.GATSBY_CALENDLY_URL , "_blank")
        }
      };
    return (
        <section className="benefitsheadingpara benefitsheadingpara--container">
            <div className="benefitsheadingpara--content-wrap">
                <h2 className="benefitsheadingpara--heading">{props.heading}</h2>
                <p className="benefitsheadingpara--subheading">{props.subHeading}</p>
                <button className="benefitsheadingpara--button" onClick={login}>{props.button}</button>
            </div>
            <div className={`benefitsheadingpara--image-wrap ${props.className}`} >
                <img src={props.image} className="benefitsheadingpara--image" alt={props.className} />
            </div>
        </section>
    )
}
export default BenefitsHeadingPara