
import React from "react";
import Header from "../components/Header"
import Footer from "../components/Footer"
import { MDXProvider } from "@mdx-js/react"
import DynosFunctionality from "../components/DynosFunctionality"
import BenefitsHeadingPara from "../components/Benefits/BenefitsHeadingPara"
import BenefitsHeadingButton from "../components/Benefits/BenefitsHeadingButton"
import SEOPage from "../components/SEOPage"
import InformationCard from "../components/InformationCard"
import "../ui-lib/styles/styleGuide.scss"


const shortcodes = { DynosFunctionality, BenefitsHeadingPara, BenefitsHeadingButton, SEOPage, InformationCard }
const Layout = ({ children }) => {

  return (
    <MDXProvider components={shortcodes}>
      <section className="bg-benefits">
        <Header headerClass="headercolor" className="benefits" />
        <main>
          <section className="mdlayout--wrap">
            <section className="mdlayout mdlayout--container">
              <section className="mdlayout-main">{children}</section>
            </section>
          </section>
        </main>
      </section>
      <Footer />
    </MDXProvider>
  )
}


export default Layout
