import React from 'react'
import "./InformationCard.scss"

const InformationCard = ({name, question, answer1, answer2, answer3, answer4}) => {
  return (
    <div className='informationcard--container'>
        <div className='informationcard--wrap'>
            <section className='informationcard--title'>
                <img src={`/${name}.png`} alt={name} className={`${name}`} />
                <h5>{name}</h5>
            </section>
            <div className={`${name} informationcard--content`}>
                <h6>{question}</h6>
                <p>{answer1}</p>
                <p>{answer2}</p>
                <p>{answer3}</p>
                <p>{answer4}</p>
            </div>
        </div>
    </div>
  )
}
export default InformationCard