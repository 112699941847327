import React from "react";
import "./BenefitsHeadingButton.scss"

const BenefitsHeadingButton = (props) => {
    const login = () => {
        if(window.location.href.includes("teachers") || window.location.href.includes("students")) {
            window.open(process.env.GATSBY_MGMT_LOGIN , "_self")
        } else {
            window.open(process.env.GATSBY_CALENDLY_URL , "_blank")
        }
      };
    return (
        <section className="BenefitsHeadingButton">
            <h3 className="BenefitsHeadingButton--heading">{props.heading}</h3>
            <button className="BenefitsHeadingButton--button button-link" onClick={login}>{props.button}</button>
        </section>
    )
}
export default BenefitsHeadingButton