import React from 'react'
// import { useStaticQuery, graphql } from "gatsby"
// import Img from "gatsby-image"
import "./DynosFunctionality.scss"

const DynosFunctionality = (props) => {
    return (
        <section className={`dynosfunctionality--wrap ${props.wrapClass}`} >
            <section className={`dynosfunctionality dynosfunctionality--container ${props.className}`}>
                <section className="dynosfunctionality--heading">
                    <h3>{props.heading}</h3>
                    {/* <p>{props.subHeading}</p> */}
                    <p dangerouslySetInnerHTML={{ __html: props.subHeading }}></p>
                </section>
                <section className={`dynosfunctionality--image ${props.design}`}>
                    <img src={`/${props.image}.png`} alt={props.alt} />
                </section>
            </section>
        </section>
    )
}
export default DynosFunctionality
